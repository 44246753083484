import React, {useContext} from "react";
import "./WorkExperience.scss";
import EducationCard from "../../components/educationCard/EducationCard";
import {workExperiences} from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";

export default function WorkExperience() {
  const {isDark} = useContext(StyleContext);
  if (workExperiences.display) {
    return (
      <div className="education-section" id="experience">
        <h1 className="education-heading">Work Experience</h1>
        <div className="education-card-container">
          {workExperiences.experience.map((school, index) => (
            <EducationCard key={index} school={school} />
          ))}
        </div>
      </div>
    );
  }
  return null;
}
