import React from "react";
import "./SoftwareSkill.scss";
import "./SoftwareSkill.css";
import {skillsSection} from "../../portfolio";
import {Fade} from "react-reveal";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export default function SoftwareSkill() {
  return (
    // <div>
    //   <div className="software-skills-main-div">
    //     <ul className="dev-icons">
    //       {skillsSection.softwareSkills.map((skills, i) => {
    //         return (
    //           <li
    //             key={i}
    //             className="software-skill-inline"
    //             name={skills.skillName}
    //           >
    //             <i className={skills.fontAwesomeClassname}></i>
    //             <p>{skills.skillName}</p>
    //           </li>
    //         );
    //       })}
    //     </ul>
    //   </div>
    // </div>

    <div className="skills-main-div">
      <div className="skills-text-div">
        <div>
          <div className="software-skills-main-div">
            <ul className="dev-icons">
              {skillsSection.softwareSkills.map(logo => {
                return (
                  <OverlayTrigger
                    key={logo.skillName}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-top`}>
                        <strong>{logo.skillName}</strong>
                      </Tooltip>
                    }
                  >
                    <li
                      id={logo.id}
                      className="software-skill-inline"
                      name={logo.skillName}
                    >
                      <img
                        src={require("../../assets/images/" + logo.src)}
                        height="55"
                      />
                    </li>
                  </OverlayTrigger>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
