import React, {useContext} from "react";
import "./publications.scss";
import PublicationCard from "../../components/publicationCard/publicationCard";
import {researchPapers} from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";

export default function Publications() {
  const {isDark} = useContext(StyleContext);
  if (researchPapers.display) {
    return (
      <div className="education-section" id="publications">
        <h1 className="education-heading">Research Papers</h1>
        <p
          className={
            isDark ? "dark-mode project-subtitle" : "subTitle project-subtitle"
          }
        >
          {researchPapers.subtitle}
        </p>
        <div className="education-card-container">
          {researchPapers.papers.map((papers, index) => (
            <PublicationCard key={index} papers={papers} />
          ))}
        </div>
      </div>
    );
  }
  return null;
}
