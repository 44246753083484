import React, {createRef, useContext} from "react";
import {Fade, Slide} from "react-reveal";
import "./publicationCard.scss";
import StyleContext from "../../contexts/StyleContext";

export default function PublicationCard({papers}) {
  const imgRef = createRef();

  const GetDescBullets = ({descBullets}) => {
    return descBullets
      ? descBullets.map((item, i) => (
          <li key={i} className="subTitle">
            {item}
          </li>
        ))
      : null;
  };
  const {isDark} = useContext(StyleContext);

  return (
    <div>
      <Fade left duration={1000}>
        <div className="publication-card">
          <div className="publication-card-right">
            <h5 id="pubication-paper-title">{papers.paperTitle}</h5>

            <div className="publication-text-details">
              <h5
                className={
                  isDark
                    ? "dark-mode publication-text-subHeader"
                    : "publication-text-subHeader"
                }
              >
                {papers.publisher}{" "}
              </h5>
              <p
                className={
                  isDark
                    ? "dark-mode publication-text-subHeader"
                    : "publication-text-subHeader"
                }
              >
                Authors: <i>{papers.authors}</i>
              </p>
            </div>
          </div>
        </div>
      </Fade>
      <Slide left duration={2000}>
        <div className="publication-card-border"></div>
      </Slide>
    </div>
  );
}
